import * as React from "react"
import { navigate } from 'gatsby';
import Link from 'components/link';

const Pager = (props) => {
	const getPageURL = (page) => {
		let pattern = props.pattern;
		if (Array.isArray(props.pattern) && props.pattern[page-1] !== undefined)pattern = props.pattern[page-1];
		else if (Array.isArray(props.pattern))pattern = props.pattern[props.pattern.length-1];
		let pagePath = pattern;
		pagePath = pagePath.replace('$skip', props.pageSize*page);
		pagePath = pagePath.replace('$limit', props.pageSize);
		pagePath = pagePath.replace('$page', page);
		pagePath = pagePath.replace('$pageSize', props.pageSize);
		pagePath = pagePath.replace('$pages', props.pages);
		return pagePath;
	}

	const goto = (page) => {
		navigate(getPageURL(page));
	}

	const getPages = (page) => {
		let basePage = 1;
		let endPage = props.pages;
		if (!!props.scale !== false && endPage > props.scale && props.page > Math.floor(props.scale/2)) {
			basePage = Math.min(props.pages-props.scale+1, props.page-Math.floor(props.scale/2));
		} else if (!!props.scale !== false && (endPage-basePage) < props.scale && endPage > props.scale) {
			basePage = basePage-(props.pageSize-(endPage-basePage))+1;
		}
		if (!!props.scale !== false) {
			endPage = Math.min(basePage+props.scale-1, Math.ceil(props.total/props.pageSize));
		}
		const pages = [];
		for (let i=basePage; i<=endPage; i++) {
			pages.push(i);
		}
		return pages;
	}

	return (
		<div className={'pagerContainer'}>
			<button onClick={() => goto(props.page-1)} className={`${('back' !== undefined ? 'back' : '')} ${(props.page-1 < 1 && 'disabled' !== undefined ? 'disabled' : '')}`} disabled={(props.page-1 < 1)}>Back</button>
			{ getPages().map((page, index, arr) => (
				<Link key={page} href={getPageURL(page)} className={`${('page' !== undefined ? 'page' : '')} ${(page === props.page ? 'current' : '')} ${('last' !== undefined && index === arr.length-1 ? 'last' : '')} ${('first' !== undefined && index === 0 ? 'first' : '')}`}>{page}</Link>
			))}
			<button onClick={() => goto(props.page+1)} className={`${('next' !== undefined ? 'next' : '')} ${(props.page+1 > props.pages && 'disabled' !== undefined ? 'disabled' : '')}`} disabled={(props.page+1 > props.pages)}>Next</button>
		</div>
	)
}

export default Pager


