import * as React from "react"
import { graphql } from "gatsby";
import { DateTime } from "luxon";

import Layout from 'components/templates/Layout';
import Content from "components/templates/Content";
import useLiveData from 'hooks/useLiveData';
import Pager from 'components/pager';
import EventCard from "components/templates/events/EventCard";
import NotFound from 'pages/404';

import { filterEvents } from 'helpers/events';

const EventsEventAllFilteredByCategory = props => {
	let category = props.data.allEventsCategory.edges[0]?.node;
	const { edges: nodes } = props.data.allEventsCard;
	const now = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
	let events = filterEvents(nodes.map(({ node }) => node), now, null, null);
	let { pageInfo } = props.data.allEventsCard;
	const { pageContext } = props;

	const { statusText: categoryProviderStatusText, response: categoryProviderResponse } = useLiveData({
		type: 'EventsCategory',
		staticData: props.data,
		apiParams: { permalink: pageContext.eventCategoryPermalink }
	})

	if (categoryProviderStatusText === 'LOADED' && categoryProviderResponse.data.length > 0) {
		category = categoryProviderResponse.data[0];
	}

	const { statusText: eventCardsProviderStatusText, response: eventCardsProviderResponse } = useLiveData({
		type: 'EventsCard',
		staticData: props.data,
		apiParams: { _page: props.pageContext.page, _pageSize: props.pageContext.limit, _sort: "showDate,name", _order: "desc", approved: true, deleted: false, archived: false, _join: "Neighborhood,Category", "Category.id": category?.id }
	});

	if (eventCardsProviderStatusText === 'LOADED' && eventCardsProviderResponse.data.length > 0) {
		events = filterEvents(eventCardsProviderResponse.data, now, null, null);
	}
	
	if (!category) {
		return <NotFound />
	}

	const pattern = [`/${pageContext.eventCategoryPermalink}-events/`, `/${pageContext.eventCategoryPermalink}-events/page-$page/`];

	return (
		<Layout headerBackgroundImage={category?.photo}>
			<Content
				headlineTitle={category.snippetDescription}
				headlineBody={category.description}
				headlineAlignment="center"
				headlineSchema="light"
			/>

			<section id="events">
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell events-flx">
							{events.length > 0 && events.map((event, index) => {
								return (
									<EventCard key={`${event.id}-${index}`} event={event} index={index} category={true} />
								)
							})}
						</div>
						{events.length === 0 && <div className="cell text-center"><h3>No events currently, Check back soon!</h3></div>}
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default EventsEventAllFilteredByCategory;

export { Head } from 'components/templates/Head';

export const query = graphql`
query DynamicCategoryEventCards($skip: Int!, $limit: Int!, $eventCategoryPermalink: String, $cardCategoryFilterPermalink: [String] = "") {
	allEventsCard(
	  limit: $limit
	  skip: $skip
	  filter: {Category: {elemMatch: {object: {permalink: {in: $cardCategoryFilterPermalink}}}}, isCurrentDate: {eq: true}}
	) {
	  edges {
		node {
		  id
		  name
		  link
		  neighborhood
		  category
		  actualStartDate
		  actualEndDate
		  startDate
		  time
		  endDate
		  Category {
			object {
			  id
			  permalink
			}
		  }
		  Neighborhood {
			object {
			  id
			  name
			}
		  }
		}
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
	allEventsCategory(filter: {permalink: {eq: $eventCategoryPermalink}}) {
	  edges {
		node {
		  id
		  name
		  snippetDescription
		  description
		  altName
		  photo
		}
	  }
	}
  }
`